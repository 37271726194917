<template>
  <v-footer
    class="py-4"
    dark
    height="auto"
  >
    <v-container class="mx-auto">
      <v-row
      class="footerBtns">
      <a
      :href="'https://www.paymentshield.co.uk/terms-and-conditions'"
      target="_blank">
        <v-btn
          text
          small
          class="mr-2"
        >
          Terms of use
        </v-btn>
      </a>
      <a
      :href="'https://www.paymentshield.co.uk/fair-processing-notice'"
      target="_blank">
        <v-btn
          text
          small
          class="mr-2"
        >
          Fair processing notice
        </v-btn>
      </a>
      <a
      :href="'https://www.paymentshield.co.uk/accessibility'"
      target="_blank">
        <v-btn
          text
          small
        >
          Accessibility
        </v-btn>
      </a>
      </v-row>
      <v-row>
        <v-col
        class="text-center">
          <p><small>Paymentshield Limited (registered number 02728936) is a company registered in England and Wales at Paymentshield House, Southport Business Park, Wight Moss Way, Southport, PR8 4HQ. Telephone calls may be recorded for security purposes and monitored under our quality control procedures.</small></p>
          <p><small>Paymentshield Limited is authorised and regulated by the Financial Conduct Authority (FCA) under registration No. 312708.See the Financial Services Register for further details.</small></p>
          <p><small>&copy; {{ new Date().getFullYear() }} Paymentshield Limited</small></p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',

    data: () => ({
      items: [
        {
          href: '#!',
          icon: 'mdi-twitter',
        },
        {
          href: '#!',
          icon: 'mdi-facebook',
        },
        {
          href: '#!',
          icon: 'mdi-linkedin',
        },
      ],
    }),
  }
</script>
